<mat-toolbar color="primary" class="example-toolbar">
  <button mat-icon-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon></button>
  <img
    class="h-100 ms-auto ms-sm-0 p-2"
    src="assets/images/shop_icon_light.png"
    alt="Shop & Win Logo"
  />
</mat-toolbar>
<mat-drawer-container class="container-vh">
  <mat-drawer #drawer mode="side" class="p-3">
    <button
      mat-button
      color="primary"
      class="justify-content-start"
      [routerLink]="['/merchants']"
      routerLinkActive="mdc-button--unelevated mat-mdc-unelevated-button"
    >
      <div class="d-flex justify-content-start align-items-center">
        <mat-icon class="me-2">storefront</mat-icon>
        <div>Comercianti</div>
      </div>
    </button>
    <button
      mat-button
      color="primary"
      class="justify-content-start"
      [routerLink]="['/reports']"
      routerLinkActive="mdc-button--unelevated mat-mdc-unelevated-button"
    >
      <div class="d-flex justify-content-start align-items-center">
        <mat-icon class="me-2">description</mat-icon>
        <div>Rapoarte</div>
      </div>
    </button>
    <button
      mat-button
      color="primary"
      class="justify-content-start"
      [routerLink]="['/categories']"
      routerLinkActive="mdc-button--unelevated mat-mdc-unelevated-button"
    >
      <div class="d-flex justify-content-start align-items-center">
        <mat-icon class="me-2">category</mat-icon>
        <div>Categorii</div>
      </div>
    </button>

    <button
      mat-button
      color="primary"
      class="justify-content-start"
      [routerLink]="['/transactions']"
      routerLinkActive="mdc-button--unelevated mat-mdc-unelevated-button"
    >
      <div class="d-flex justify-content-start align-items-center">
        <mat-icon class="me-2">compare_arrows</mat-icon>
        <div>Cashout</div>
      </div>
    </button>

    <button mat-button color="primary" class="justify-content-start mt-auto" (click)="logout()">
      <div class="d-flex justify-content-start align-items-center">
        <mat-icon class="me-2">logout</mat-icon>
        <div>Logout</div>
      </div>
    </button>
  </mat-drawer>
  <mat-drawer-content>
    <mat-card
      class="m-2 mx-md-auto my-md-5 p-2 p-md-4 main-card-max-width"
      [ngClass]="router.url === '/transactions' ? 'transactions-card-max-width' : ''"
    >
      <mat-card-content>
        <router-outlet />
      </mat-card-content>
    </mat-card>
  </mat-drawer-content>
</mat-drawer-container>
